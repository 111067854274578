import request from '@/utils/request'
export function activityFeedbackUpdate(data) {
  return request({
    url: '/activityFeedback/update',
    method: 'post',
    data:data
  })
}
export function activityFeedbackDelete(data) {
  return request({
    url: '/activityFeedback/deleteById',
    method: 'post',
    params:data
  })
}
export function activityFeedbackDelete2(data) {
  return request({
    url: '/activityFeedback/delete',
    method: 'post',
    params:data
  })
}
export function activityFeedback(data) {
  return request({
    url: '/activityFeedback/add',
    method: 'post',
    data:data
  })
}
export function readActivityUpdate(params) {
  return request({
    url: '/readActivity/update',
    method: 'post',
    data:params
  })
}
export function evaluateRecordAdd(params) {
  return request({
    url: '/evaluateRecord/add',
    method: 'post',
    params:params
  })
}
export function addActivityQuestion(params) {
  return request({
    url: '/readQuestion/addActivityQuestion',
    method: 'post',
    params:params
  })
}

export function activityInfoAdd(data) {
  return request({
    url: '/activityInfo/insert',
    method: 'post',
    data:data,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  })
}
export function updateExamineStatusById(params) {
  return request({
    url: '/activityInfo/updateExamineStatusById',
    method: 'post',
    params:params
  })
}
export function activityInfoUpdate(data) {
  return request({
    url: '/activityInfo/update',
   method: 'post',
   data:data,
   headers: {'Content-Type': 'application/x-www-form-urlencoded'}
  })
}

export function activityObjectAdd(params) {
  return request({
    url: '/activityObject/add',
    method: 'post',
    params:params
  })
}

export function activityInfoDelete(params) {
  return request({
    url: '/activityInfo/delete',
    method: 'post',
    params:params
  })
}

export function deleteActivityQuestion(params) {
  return request({
    url: '/readQuestion/deleteActivityQuestion',
    method: 'post',
    params:params
  })
} 
export function activityCode(params) {
  return request({
    url: '/activityInfo/getQRCode',
    method: 'get',
    params:params
  })
}
export function activityList(params) {
  return request({
    url: '/activityInfo/list',
    method: 'get',
    params:params
  })
}
export function activityFeedbackInfo(params) {
  return request({
    url: '/activityFeedback/info',
    method: 'get',
    params:params
  })
}
export function activityInfo(params) {
  return request({
    url: '/activityInfo/info',
    method: 'get',
    params:params
  })
}
export function sysArea(params) {
  return request({
    url: '/sysArea/list',
    method: 'get',
    params:params
  })
}
export function schoolList(params) {
  return request({
    url: '/school/schoolList',
    method: 'get',
    params:params
  })
}

export function teacherList(params) {
  return request({
    url: '/organBusiness/backUserList',
    method: 'get',
    params:params
  })
}

export function dictList(params) {
  return request({
    url: '/dict/list',
    method: 'get',
    params:params
  })
}
export function getUserJoin(params) {
  return request({
    url: '/userJoin/getUserJoin',
    method: 'get',
    params:params
  })
}
